import React from "react";
import MVPbanner from "../../img/MVPbanner-ourofferings.png";
import smallfeaturebanner from "../../img/smallfeatureoffering.png";
import consultbanner from "../../img/techconsulting.png";
function OurOfferingServices() {
  return (
    <section className="bg-gray-50 dark:bg-gray-800">
      <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 sm:space-y-20 lg:space-y-20 lg:py-24 lg:px-6 xs:space-y-20">
        {/* <!-- Row --> */}
        <div
          id="mvpservices"
          className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16"
        >
          <div className="text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-3xl font-sans font-extrabold tracking-tight text-gray-900 dark:text-white">
              Minimal Viable Product (MVP) Development
            </h2>
            <p className="mb-8 font-light font-sans lg:text-xl">
              Startups and organizations of all sizes need speed, readiness and
              deep architectural know-how from their technology & consulting
              partners. We partner with you to create a Minimal Viable Product
              following the leanest path. The ultimate goal is to build right
              thing that solves primary problem and identify product market fit
              as quick as possible without breaking the bank.{" "}
            </p>
            {/* <!-- List --> */}
            <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium font-sans leading-tight text-gray-900 dark:text-white">
                  Continuous integration and deployment
                </span>
              </li>
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium font-sans leading-tight text-gray-900 dark:text-white">
                  Development workflow
                </span>
              </li>
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight font-sans text-gray-900 dark:text-white">
                  Knowledge management and Design Thinking
                </span>
              </li>
            </ul>
            <p className="mb-8 font-light font-sans lg:text-xl">
              Deliver great service experiences fast - without the complexity of
              traditional ITSM solutions.
            </p>
          </div>
          <img
            className=" w-full mb-4 rounded-lg lg:mb-0 lg:flex"
            src={MVPbanner}
            alt="dashboard feature "
          />
        </div>
        {/* <!-- Row --> */}
        <div
          id="smallfeature"
          className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16"
        >
          <img
            className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
            src={smallfeaturebanner}
            alt="feature "
          />
          <div className="text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-3xl font-sans font-extrabold tracking-tight text-gray-900 dark:text-white">
              Small Feature Developement
            </h2>
            <p className="mb-8 font-light font-sans lg:text-xl">
              Building a product or an solution with extreme focus to the market
              to solve a primary problem, but chances are that if you cannot
              find that one killer feature that can stand on its own - at least
              in with early users - adding more features will not make the
              product a must have.
            </p>
            {/* <!-- List --> */}
            <ul className="pt-8 space-y-5 border-t font-sans border-gray-200 my-7 dark:border-gray-700">
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium font-sans leading-tight text-gray-900 dark:text-white">
                  Identify Accelerators
                </span>
              </li>
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium font-sans leading-tight text-gray-900 dark:text-white">
                  Implementation roadmap, milestones and planning
                </span>
              </li>
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-sans font-medium leading-tight text-gray-900 dark:text-white">
                  Alignment Customer Vision to the market
                </span>
              </li>
            </ul>
            <p className="font-light font-sans lg:text-xl">
              We assist in improving the existing product by Building a killer
              feature that can take your product to the next level
            </p>
          </div>
        </div>

        {/* Row */}
        <div
          id="techconsulting"
          className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16"
        >
          <div className="text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-3xl font-sans font-extrabold tracking-tight text-gray-900 dark:text-white">
              Technology Consulting
            </h2>
            <p className="mb-8 font-light font-sans lg:text-xl">
              Our technology consultants utilize cross-sector expertise and
              global insights to help tech companies compete and thrive among
              constant industry innovation.
            </p>
            {/* <!-- List --> */}
            <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-sans font-medium leading-tight text-gray-900 dark:text-white">
                  Development Workflows
                </span>
              </li>
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-sans font-medium leading-tight text-gray-900 dark:text-white">
                  Product Strategy
                </span>
              </li>
              <li className="flex space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-sans font-medium leading-tight text-gray-900 dark:text-white">
                  Advanced Analytics and Planning
                </span>
              </li>
            </ul>
            <p className="mb-8 font-light font-sans lg:text-xl">
              We got the industry experts in all verticals to set you in the
              right path
            </p>
          </div>
          <img
            className=" w-full mb-4  lg:mb-0 lg:flex"
            src={consultbanner}
            alt="dashboard feature "
          />
        </div>
      </div>
    </section>
  );
}
export default OurOfferingServices;
