import React from "react";
import ContactModalButton from "../utils/contactModal";

function CTAOfferings() {
  return (
    <section className="grid items-center justify-center grid-cols-1 px-4 py-5 text-center lg:grid-cols-3">
      <div className="col-auto lg:col-start-2">
        <p className="mb-2 text-base font-semibold text-primary"></p>
        <h2 className="mb-6 font-sans text-3xl font-semibold tracking-tight md:leading-tight md:text-4xl md:mb-6">
          Ready to Build your Unicorn Company !
        </h2>
        <ContactModalButton buttonName={"Get Your MVP Now !"} />
      </div>
    </section>
  );
}
export default CTAOfferings;
