import React from "react";
import bannner from "../../img/offeringbanner.webp";
import ContactModalButton from "../utils/contactModal";
function OurOfferingsHero() {
  return (
    <>
   
<section className="grid grid-cols-1 gap-0 bg-blue-100 bg-opacity-25 md:grid-cols-2">
  <div className="flex flex-col items-start justify-center px-4 mt-24 lg:px-20">
    
    <h1 className="mb-6 text-4xl font-sans font-bold leading-tight text-gray-900 md:text-4xl lg:text-5xl">Your journey to building an Unicorn Startup starts here</h1>
    
    <p className="pr-0 mb-6 xl:px-13 font-sans text-lg sm:justify-center font-sans text-gray-800 tracking-relaxed lg:pr-16">MVP Tribe is a one-stop shop for our MVP professionals. If your business is looking for a high-quality and affordable team that can develop an MVP prototype in no time, then it's time to join our tribe.<br/><br/><ContactModalButton  buttonName={'Get Started'}/></p>
    
  </div>
  <div>
    <img
      src={bannner}
      alt="banner"
      class="object-cover w-full h-64 bg-gray-100 md:h-full"
      loading="lazy"
    />
  </div>
</section>
</>
  );
}

export default OurOfferingsHero;
