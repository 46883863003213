import React from "react";
import OurOfferingsHero from "../components/Our-offeringsPage/heroSectionouroffering";
import CTAOfferings from "../components/Our-offeringsPage/offeringscta";
import OurOfferingServices from "../components/Our-offeringsPage/ourofferingServices";
import SiteSEO from "../components/siteSEO";

function OurOfferingsPage(){
    return(
        <>
        <SiteSEO
        title={'Our Offerings | MVP Tribe'}
        description={"MVP Tribe offers exceptional MVP Development, Small Feature Development and Consulting services. We'll bring your idea to life in no time. This is where innovation meets value and we build minimum viable products (MVP) to test the market and validate business ideas"}
        keywords={'MVP Development , Technology Consulting, Feature Development, SAAS Products, Software Development'}
        image={''}
        />
        <OurOfferingsHero/>
        
        <OurOfferingServices />
        <CTAOfferings/>
</>
    )
}
export default OurOfferingsPage